<template>
  <router-view/>
</template>
<script>
const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};
const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};
</script>
<style lang="scss">
@font-face {
  font-family: 'AlimamaShuHeiTi-Bold';  /* 字体名称 */
  src: url('@/fonts/AlimamaShuHeiTi-Bold.otf') format('truetype'); /* 字体文件的URL及其格式（如.ttf、.otf、.woff、.woff2等） */
  font-weight: normal;         /* 字体粗细 */
  font-style: normal;          /* 字体风格 */ 
}
@font-face {
  font-family: 'PingFangSC-Medium';  /* 字体名称 */
  src: url('@/fonts/PINGFANG MEDIUM.TTF') format('truetype'); /* 字体文件的URL及其格式（如.ttf、.otf、.woff、.woff2等） */
  font-weight: normal;         /* 字体粗细 */
  font-style: normal;          /* 字体风格 */ 
}
@font-face {
  font-family: 'PingFangSC-bold';  /* 字体名称 */
  src: url('@/fonts/PINGFANG BOLD.TTF') format('truetype'); /* 字体文件的URL及其格式（如.ttf、.otf、.woff、.woff2等） */
  font-weight: normal;         /* 字体粗细 */
  font-style: normal;          /* 字体风格 */ 
}
//input 隐藏上下箭头
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { 
    -webkit-appearance: none; 
}
.ant-layout-header{
  height: 48px !important;
  // padding: 0 24px 0 32px;
  line-height: 48px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.ant-layout-sider{
  height: auto !important;
  min-height: 100vh;
}
.ant-page-header{
  padding: 0;
  .ant-page-header-heading-title{
    font-size: 18px;
    line-height: 1;
  }
}
.ant-layout-content{
  padding: 12px;
  background-color: #F0F2F5;
}
.ant-form{
  .ant-form-item.vertical{
    display: block;
    .ant-form-item-label{
      width: 100% !important;
      text-align: left;
    }
    .ant-form-item-control{
      width: 100% !important;
      flex: none;
    }
  }
  .ant-picker{
    width: 100%;
  }
  .form-btns{
    .ant-btn{
      margin-right: 12px;
    }
  }
  .anticon{
    cursor: pointer;
    &.anticon-minus-circle,
    &.anticon-plus-circle{
      font-size: 24px !important;
    }

    &.anticon-plus-circle{
      color: #37963D;
    }
    &.anticon-minus-circle{
      color: #DEDEDE;
    }
  }
  .ant-input-disabled,
  .ant-select-disabled .ant-select-selection-item,
  .ant-picker-disabled input{
    color: rgba(0, 0, 0, 0.88) !important;
  }
}
.search-form{
  .ant-row{
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .ant-input-affix-wrapper,
  .ant-select,
  .ant-picker{
    width: 236px;
  }
  .search-btns{
    text-align: right;
    .ant-btn{
      margin-left: 12px;
    }
  }
}
.ant-pagination{
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ant-pagination-total-text{
    margin-right: auto;
  }
}
.ant-card{
  margin-bottom: 12px;
  .ant-card-body{
    padding: 12px;
  }
}
.upload-tip{
  display: block;
  font-size: 14px;
  color: rgba(0,0,0,0.65);
  margin-top: 8px;
}
.ant-input-group-compact{
  .ant-input{
    cursor: pointer;
  }
  &:hover{
    .upload-del{
      display: flex;
    }
  }
  .upload-del{
    height: 100%;
    color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 90px;
    z-index: 999;
    display: none;
  }
}
.ant-upload-list-item-name{
  cursor: pointer;
}
.ant-input-disabled{
  cursor: not-allowed !important;
}
.ant-upload{
  .view-btn{
    display: block;
    padding: 4px 15px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
    .anticon{
      margin-right: 8px;
    }
  }
}
.table-out-btns{
  margin-bottom: 12px;
  .ant-btn{
    margin-right: 12px;
  }
  .ant-select{
    width: 200px;
    float: right;
  }
}

.ant-table{
  .ant-table-tbody > .ant-table-row:nth-child(odd),
  .ant-table-tbody > .ant-table-row:nth-child(odd) .ant-table-cell-fix-left,
  .ant-table-tbody > .ant-table-row:nth-child(odd) .ant-table-cell-fix-right{
    background-color: #F5F5F5;
  }
  .ant-table-tbody >.ant-table-row.ant-table-row-selected{
    td {
      background: none !important;
    }
  }
  .table-btn{
    white-space: nowrap;
    margin: 0 10px;
  }
  .table-tag{
    margin-left: 8px;
  }
  .red{
    color: #F5222D;
  }
  .green{
    color: #37963D;
  }
  .orange{
    color: #FA8C16;
  }
  .grey{
    color: grey;
  }
}
.ant-modal{
  .ant-modal-content{
    border-radius: 4px;
    overflow: hidden;
  }
  .model-btns{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-btn{
      margin-left: 12px;
    }
  }
}
.ant-tabs{
  .ant-tabs-tab:hover,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active{
    color: #389E0D;
  }
  .ant-tabs-ink-bar{
    background: #389E0D;
  }
  .ant-tabs-content{
    padding-top: 20px;
  }
  .ant-tabs-extra-content{
    .ant-btn{
      margin-left: 14px;
    }
  }
}
.my-btns{
  .ant-btn{
    margin-right: 14px;
  }
}
</style>