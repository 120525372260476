import { createRouter, createWebHashHistory } from 'vue-router'
import { setMenu, setModuleMenu } from '@/utils/permission'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home')
  },
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login')
  },
  {
    path: '/:pathMatch(.*)*',
    hide: true,
    component: () => import('@/views/404'),
  },
  {
    path: '/module',
    name: 'Module',
    component: () => import('@/views/module')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  if(!router.options.routes[0].children && to.path !== "/login"){
    setMenu();  //生成所有菜单

    if(sessionStorage.getItem('moduleMenuIds')){ setModuleMenu() }  //如果有模块id，渲染模块菜单
    
    next({
      path: to.path,
      query: to.query,
      replace: true
    });
  }else{
    next()
  }
})


export default router
